import React, { useState, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import SignIn from './sign_in';
import SignUp from './sign_up';
import AppBarComponent from './components/app_bar';
import ListDrawer from './components/list_drawer';
import CoinsView from './coins';
import PortfoliosView from './portfolios';
import SharedPortfoliosView from './shared_portfolios';
import { getUserData, UserContext } from './auth';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
    paddingTop: '90px',
    background: '#eef4f9'
  }
}));


// TODO not finished
function NotFoundPage() {
  return (
    <div>
      Page Not Found
    </div>
  );
}


export default function App() {
  const classes = useStyles();
  const { userSignedIn } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState('');

  return (
    <div className={classes.root}>
      {
        userSignedIn ? (
          <>
            <AppBarComponent
              userData={userSignedIn ? getUserData() : null}
              selectedItem={selectedListItem}
              open={open}
              setOpen={setOpen}
            />
            <ListDrawer
              open={open}
              setOpen={setOpen}
              selectedListItem={selectedListItem}
              setSelectedListItem={setSelectedListItem}
            />
          </>
        ) : null
      }
      <Switch>
        <PrivateRoute exact path="/" component={PortfoliosView} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-in" component={SignIn} />
        <PrivateRoute exact path="/portfolios/:id" component={PortfoliosView} />
        <PrivateRoute exact path="/portfolios" component={PortfoliosView} />
        <PrivateRoute exact path="/coins" component={CoinsView} />
        <PrivateRoute exact path="/shared_portfolios/:id" component={SharedPortfoliosView} />
        <PrivateRoute exact path="/shared_portfolios" component={SharedPortfoliosView} />
        <Route exact component={NotFoundPage} />
      </Switch>
    </div>
  );
}


function PrivateRoute({ component: Component, ...rest }) {
  const classes = useStyles();
  const { userSignedIn, socket } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => (userSignedIn ? (
        <main className={classes.content}>
          <div className={classes.toolbar} >
            <Component {...props} socket={socket} />
          </div>
        </main>
      ) : (
        <Redirect
          to={{pathname: '/sign-in', state: {referer: props.location}}}
        />
      ))
      }
    />
  );
}
