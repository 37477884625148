import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Tooltip,
  makeStyles,
  TextField
} from '@material-ui/core';
import { hasKey } from '../utils';


const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 10
  }
}));


export default function NewPortfolio(props) {
  const { portfolios, submit } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      {
        open ? (
          <CreateNewPortfolio
            open={open}
            setOpen={setOpen}
            portfolios={portfolios}
            submit={submit}
          />
        ) : null
      }
      <Tooltip title={'New Portfolio'}>
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          size="medium"
          className={classes.fab}
          onClick={() => setOpen(true) }
        >
          New
        </Fab>
      </Tooltip>
    </div>
  );
}

NewPortfolio.defaultProps = {
  portfolios: {}
};

NewPortfolio.propTypes = {
  portfolios: PropTypes.object,
  submit: PropTypes.func.isRequired
};


function CreateNewPortfolio(props) {
  const { open, setOpen, portfolios, submit } = props;
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const nameRef = useRef();

  const handleOnChange = (event) => {
    setError(false);
    setName(event.target.value);
    const val = event.target.value.trim();
    if (hasKey(portfolios, val)) setError(true);
  };

  const disableCreateBtn = () => {
    if (name.trim().length === 0 || error === true) return true;
    return false;
  };

  const handleCreateBtn = () => {
    setOpen(false);
    submit(name);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!name) nameRef.current.focus();
    }, 200);

    return () => { clearTimeout(timeout); };
  }, [name]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="create-a-new-portfolio-dialog"
        fullWidth
      >
        <DialogTitle id="add-subjects-dialog-title" onClose={() => setOpen(false)}>
          Create a New Portfolio
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            inputRef={nameRef}
            fullWidth
            required
            margin="dense"
            variant="filled"
            id="subject-name"
            value={name || ''}
            label="Portfolio Name"
            error={error}
            helperText={error ? 'Portfolio with this name already exists' : ''}
            onChange={handleOnChange}
            onBlur={(event) => setName(event.target.value.trim())}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableCreateBtn()}
            onClick={handleCreateBtn}
            color="primary"
            autoFocus
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateNewPortfolio.defaultProps = {
  portfolios: {}
};

CreateNewPortfolio.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  portfolios: PropTypes.object,
  submit: PropTypes.func.isRequired
};
