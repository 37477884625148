import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  ButtonGroup,
  Paper
} from '@material-ui/core';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';


const toggleButtons = [
  {idx: 0, name: '24 Hours'},
  {idx: 1, name: '1 Week'},
  {idx: 2, name: '30 Days'},
  {idx: 3, name: '3 Months'},
  {idx: 4, name: '1 Year'}
];


function CustomTooltip({ active, payload, label }) {
  if (active && payload && label) {
    return (
      <div style={{
        backgroundColor: '#444568',
        color: 'white',
        fontSize: '12px',
        fontWeight: 300,
        padding: '10px',
        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        textAlign: 'left'
      }}
      >
        <p>
          {label}
        </p>
        <p>
          {`Equity Value: $${payload[0].value} `}
        </p>
      </div>
    );
  }

  return null;
}

CustomTooltip.defaultProps = {
  label: undefined,
  payload: undefined
};

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array,
  label: PropTypes.string
};


function PortfolioChart(props) {
  const { chartData, wsData } = props;
  const [type, setType] = useState(0);
  const [data, setData] = useState(chartData);

  useEffect(() => {
    if (wsData && wsData.length) {
      setData(wsData);
    } else if (chartData && chartData.length) {
      if (!data) {
        setData(chartData);
      } else if (chartData[0]._id !== data[0]._id) {
        setData(chartData);
      }
    }
  }, [wsData, chartData]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{textAlign: 'left'}}>
        <ButtonGroup size='small' aria-label="small outlined button group">
          {
            toggleButtons.map((btn) => (
              <Button
                style={{backgroundColor: btn.idx === type ? 'lightgray' : 'white'}}
                size='small'
                key={btn.idx}
                onClick={() => setType(btn.idx)}
              >
                {btn.name}
              </Button>
            ))
          }
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width="99%" height={452}>
          <AreaChart
            data={
              data[type].data.map((d, i) => ({time: data[type].label[i], value: d}))
            }
            margin={{
              top: 5,
              right: 20,
              bottom: 5,
              left: 0
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00be9e" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#00be9e" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" vertical={false} />
            <XAxis dataKey="time" interval='preserveStart' />
            <YAxis
              orientation="right"
              name="valueName"
              domain={['auto', 'auto']}
            />
            <Tooltip content={CustomTooltip}/>
            <Area
              type="monotone"
              dataKey="value"
              stroke="#00be9e"
              dot={({ key }) => <span key={key} />} fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
}

PortfolioChart.defaultProps = {
  wsData: []
};

PortfolioChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  wsData: PropTypes.array
};


/*
 * This component is the main component to show portfolio's (including shared)
 * chart data.
 */
export default function PortfolioChartComp(props) {
  const { classes, portfolios, selected, chartData, wsChartData, portType } = props;

  return (
    <Paper className={classes.paper} >
      {
        selected && chartData.length ? (
          <PortfolioChart
            chartData={chartData}
            wsData={
              wsChartData.length && selected ? (
                wsChartData[0]._id === selected._id ? wsChartData : []
              ) : []
            }
          />
        ) : !portfolios.length ? (
          <div>
            {
              portType === 'owner' ? (
                'Click the NEW Button to Create a New Portfolio'
              ) : 'You do not have shared portfolios'
            }
          </div>
        ) : (
          <div>
          No Chart Data Yet
          </div>
        )
      }
    </Paper>
  );
}

PortfolioChartComp.defaultProps = {
  portfolios: [],
  selected: null,
  chartData: [],
  wsChartData: []
};

PortfolioChartComp.propTypes = {
  classes: PropTypes.object.isRequired,
  portfolios: PropTypes.array,
  selected: PropTypes.object,
  chartData: PropTypes.array,
  wsChartData: PropTypes.array,
  portType: PropTypes.string.isRequired
};
