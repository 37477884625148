import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Typography, Paper, InputBase, makeStyles } from '@material-ui/core';
import { api } from './api';
import { getAuthHeader } from './auth';
import SpinnerPage from './components/spinner_page';
import ScrollTop from './components/scroll_top';
import { tableIcons } from './table_icons';
import { getExchangesCoins, createAlert  } from './utils';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '85%'
  },
  input: {
    marginLeft: theme.spacing(1)
  },
  searchButton: {
    float: 'left',
    padding: 10
  },
  searchTypeDev: {
    minWidth: '10px'
  },
  searchTypeDevProp: {
    minWidth: '180px'
  }
}));


function getMarketCapNumber(num) {
  if (num < 1000000) return `$${num}`;
  const units = ['M', 'B', 'T', 'Q'];
  const unit = Math.floor((num / 1.0e+1).toFixed(0).toString().length);
  const r = unit % 3;
  const x =  Math.abs(Number(num)) / Number(`1.0e+${(unit - r)}`).toFixed(2);
  return `$${x.toFixed(2)}${units[Math.floor(unit / 3) - 2]}`;
}


function stringMatching(string, value) {
  if (!value || !string) return false;

  const regex = new RegExp(value.toLowerCase(), 'g');
  const found = (string.toLowerCase()).match(regex);
  if (found) return true;
  return false;
}


function RenderNameColumn({ icon_url, name, ticker }) {
  return (
    <div>
      <img src={icon_url} alt="logo" style={{ width: 24, borderRadius: '40%', float: 'left' }}/>
      <Typography display="inline" style={{ color: 'black', marginLeft: '7px' }}>
        {name}
      </Typography>
      <Typography display="inline" style={{ padding: 'left', color: '#c1c1c1', marginLeft: '5px' }}>
        {`(${ticker})`}
      </Typography>
    </div>
  );
}

RenderNameColumn.propTypes = {
  icon_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired
};


function RenderMarketCapColumn({ market_cap }) {
  return (
    <div>
      <Typography style={{ color: '#000000' }}>
        {
          market_cap ? (
            <NumberFormat value={market_cap} displayType={'text'} format={getMarketCapNumber} prefix={'$'} />
          ) : 'Unknown'
        }
      </Typography>
    </div>
  );
}

RenderMarketCapColumn.defaultProps = {
  market_cap: 0
};

RenderMarketCapColumn.propTypes = {
  market_cap: PropTypes.number
};


function RenderChange24hColumn({ change_24h }) {
  return (
    <div>
      <Typography style={{ color: change_24h < 0 ? 'red' : '#00be9e' }}>
        {`${change_24h}%`}
      </Typography>
    </div>
  );
}

RenderChange24hColumn.defaultProps = {
  change_24h: 0
};

RenderChange24hColumn.propTypes = {
  change_24h: PropTypes.number
};


function RenderChangeUTCColumn({ change_utc }) {
  return (
    <div>
      <Typography style={{ color: change_utc < 0 ? 'red' : '#00be9e' }}>
        {`${change_utc}%`}
      </Typography>
    </div>
  );
}

RenderChangeUTCColumn.defaultProps = {
  change_utc: 0
};

RenderChangeUTCColumn.propTypes = {
  change_utc: PropTypes.number
};


function RenderPriceColumn({ price }) {
  return (
    <div>
      <Typography style={{ color: '#000000' }}>
        {`$${price}`}
      </Typography>
    </div>
  );
}

RenderPriceColumn.defaultProps = {
  price: 0
};

RenderPriceColumn.propTypes = {
  price: PropTypes.number
};


function SearchComponent(props) {
  const classes = useStyles();
  const { data, setData } = props;
  const [originalData, setOriginalData] = useState([]);
  const [text, setText] = useState('');

  const handleSearchTyping = (event) => {
    const value = event.target.value.trim();
    setText(value);
    if (value) {
      const result = data.filter((coin) => stringMatching(coin.name, value) ||
      stringMatching(coin.ticker, value));
      setData(result);
    } else {
      setData(originalData);
    }
  };

  useEffect(() => {
    setOriginalData(props.data);
  }, []);

  return (
    <Paper component="form" className={classes.root}>
      <div className={classes.searchButton} aria-label="search">
        <SearchIcon />
      </div>
      <InputBase
        value={text}
        fullWidth
        className={classes.input}
        placeholder="Search"
        onChange={(event) => handleSearchTyping(event)}
        onBlur={(event) => setText(event.target.value.trim())}
      />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={() => {
          setText('');
          setData(originalData);
        }}
      >
        <ClearIcon />
      </IconButton>
    </Paper>
  );
}

SearchComponent.defaultProps = {
  data: []
};

SearchComponent.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func.isRequired
};


export default function CoinsView({ socket, props }) {
  const [coins, setCoins] = useState([]);
  const [runSpinningPage, setRunSpinningPage] = useState(false);
  const alert = createAlert(useSnackbar().enqueueSnackbar);

  async function fetchCoins() {
    try {
      const rv = await api.get('/coin', getAuthHeader());
      setCoins(getExchangesCoins(rv.data));
    } catch (error) {
      alert('Error while fetching coins', error);
    }
  }


  useEffect(() => {
    (async () => {
      setRunSpinningPage(true);
      await fetchCoins();
      setRunSpinningPage(false);
    })();
  }, []);


  useEffect(() => {
    if (socket) {
      socket.on('ws_coins', (data) => {
        if (data) setCoins(data);
      });
    }

    // Cleanup.
    return () => { if (socket) socket.off('ws_coins'); };
  }, [socket]);


  return (
    <div style={{flexGrow: 1}}>
      {
        runSpinningPage ? (
          <SpinnerPage />
        ) : (
          <>
            <div id="back-to-top-anchor" />
            <Grid container id="back-to-top-anchor" spacing={2}>
              <Grid item xs={12}>
                <SearchComponent data={coins} setData={setCoins} />
              </Grid>
              <Grid item xs={12}>
                <MaterialTable
                  style={{ borderRadius: '10px' }}
                  icons={tableIcons}
                  title=''
                  data={coins}
                  options={{
                    sorting: true,
                    search: false,
                    paging: false,
                    headerStyle: {
                      borderWidth: '1px',
                      borderColor: 'black',
                      color: '#848484'
                    }
                  }}
                  components={{
                    Toolbar: () => (
                      <div style={{padding: '5px'}}>
                      </div>
                    )
                  }}
                  columns={[
                    {
                      title: 'Name',
                      field: 'name',
                      render: (row) => <RenderNameColumn
                        icon_url={row.icon_url} name={row.name} ticker={row.ticker}
                      />
                    }, {
                      title: 'Price',
                      field: 'price',
                      render: (row) => <RenderPriceColumn price={row.price} />
                    }, {
                      title: 'Change (00:00 UTC)',
                      field: 'change_utc',
                      render: (row) => <RenderChangeUTCColumn change_utc={row.change_utc} />
                    }, {
                      title: 'Change 24H',
                      field: 'change_24h',
                      render: (row) => <RenderChange24hColumn change_24h={row.change_24h} />
                    }, {
                      title: 'Market Cap',
                      field: 'market_cap',
                      defaultSort: 'desc',
                      render: (row) => <RenderMarketCapColumn market_cap={row.market_cap} />
                    }
                  ]}
                />
                <br />
              </Grid>
            </Grid>
            <ScrollTop {...props} />
          </>
        )
      }
    </div>
  );
}

CoinsView.defaultProps = {
  socket: null,
  props: undefined
};

CoinsView.propTypes = {
  socket: PropTypes.object,
  props: PropTypes.object
};
