import React, { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from './components/copyright';
import { UserContext } from './auth';
import { api } from './api';
import { verifyPlainPassword, createAlert } from './utils';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));


export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const { userSignedIn, userSignIn } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [signUpError, setSignUpError] = useState('');
  const alert = createAlert(useSnackbar().enqueueSnackbar);

  if (userSignedIn) history.push('/coins');

  async function SignUpUser(request) {
    try {
      const rv = await api.post('/user', request);
      if (rv.data.error) {
        setSignUpError(rv.data.error);
        return null;
      }
      return rv.data;
    } catch (error) {
      alert('Error', error);
    }

    return null;
  }

  const handleSignUp = async () => {
    if (!username && !password) {
      setSignUpError('Please provide username and password');
      return;
    }

    setSignUpError('');
    setErrorText('');
    if (username.length < 4) {
      setErrorText('Username length must be at least 4 characters');
      return;
    }
    if (!verifyPlainPassword(password)) {
      setErrorText(`
        Password must be:
        at least 6 characters, include a digit number,
        an upper case and a lower case letters, and a special character.
      `);
      return;
    }

    const rv = await SignUpUser({username, password});
    if (rv) {
      userSignIn(rv);
      history.push('/coins');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {
          signUpError ? (
            <Alert severity="error">
              {signUpError}
            </Alert>
          ) : null
        }
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={(event) => setUsername(event.target.value)}
                onBlur={(event) => setUsername((event.target.value).trim())}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={errorText.length !== 0}
                helperText={errorText.length ? errorText : ''}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={(event) => setPassword((event.target.value).trim())}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
          <Grid item>
            <Link href="sign-in" variant="body2">
              Already have an account? Sign In
            </Link>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
