import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getAuthHeader } from '../auth';
import { api } from '../api';
import { createAlert } from '../utils';


function getCoinNamesList(exchange) {
  return Object.keys(exchange).filter((e) => exchange[e].price.USD).map((e) => (
    {name: exchange[e].name, ticker: e}
  )).sort((a, b) => (a.name > b.name ? 1 : -1));
}


export default function BuyCoins(props) {
  const {
    open, setOpen, portfolioID, portfolioCash, updatePortfolio
  } = props;
  const alert = createAlert(useSnackbar().enqueueSnackbar);
  const [exchangeName, setExchangeName] = useState('');
  const [ticker, setTicker] = useState('');
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [exchange, setExchange] = useState({});
  const [exchangesData, setExchangesData] = useState([]);
  const [errorText, setErrorText] = useState({
    amount: '',
    price: '',
    fee: ''
  });


  const setErrorValue = (key, text) => {
    setErrorText({...errorText, [key]: text});
  };

  const resetErrorValues = () => {
    setErrorText({...errorText, amount: '', price: '', fee: ''});
  };

  async function fetchExchangesData() {
    try {
      const rv = await api.get('/coin', getAuthHeader());
      setExchangesData(rv.data);
    } catch (error) {
      alert('Error while fetching exchanges', error);
    }
  }

  const disableBuyBtn = () => {
    const err = errorText.amount || errorText.price || errorText.fee;
    if (!exchangeName || !ticker || amount <= 0 || price < 0 || err) return true;
    const total_spend = (amount * price) + fee;
    if (total_spend > portfolioCash) {
      setErrorValue('amount', `You do not have enough cash to buy ${amount} coin(s)`);
      return true;
    }
    return false;
  };

  const handleTicker = (event) => {
    resetErrorValues();
    const ticker_str = event.target.value;
    setTicker(ticker_str);
    setPrice(exchange[ticker_str].price.USD);
  };

  const handleSelectExchange = (event) => {
    const value = (event.target.value).toLowerCase();
    setExchangeName(event.target.value);
    exchangesData.forEach((item) => (item._id === value ? setExchange(item.data) : ''));
  };

  const handleAmount = (event) => {
    resetErrorValues();
    setAmount(Number(event.target.value));
    const value = Number(event.target.value);
    if (value <= 0) {
      setErrorValue('amount', 'Amount must be greater than zero');
    }
    if (exchange && ticker) {
      const total_spend = Number((value * price).toFixed(2));
      if (total_spend > portfolioCash) {
        setErrorValue('amount', `You may not have enough cash to buy ${value} coin(s)`);
      }
    }
  };

  const handlePrice = (event) => {
    resetErrorValues();
    setPrice(Number(event.target.value));
    const value = Number(event.target.value);
    if (value <= 0) {
      setErrorValue('price', 'Price must be greater than zero');
    }
  };


  const handleFee = (event) => {
    resetErrorValues();
    setFee(Number(event.target.value));
    const value = Number(event.target.value);
    if (value < 0) {
      setErrorValue('fee', 'Fee cannot be negative');
    }
  };

  const handleBuyBtn = async () => {
    const request = {
      _id: portfolioID,
      ticker,
      amount,
      price,
      exchange: exchangeName,
      fee
    };
    updatePortfolio(request, 'buy_coin');
    setOpen(false);
  };

  useEffect(() => {
    fetchExchangesData();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="deposit-withdraw-portfolio-dialog"
        fullWidth
      >
        <DialogTitle id="add-subjects-dialog-title" onClose={() => setOpen(false)}>
          Buy Coins
        </DialogTitle>
        <DialogContent dividers>

          <FormControl fullWidth={true}>
            <InputLabel id="simple-select-exchange">
              Select Exchange *
            </InputLabel>
            <Select
              labelId="simple-select-exchange"
              id="simple-select-e"
              value={exchangeName || ''}
              onChange={handleSelectExchange}
            >
              {
                ['Gemini'].map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="simple-select-coin">
              Select Coin *
            </InputLabel>
            <Select
              labelId="simple-select-coin"
              id="simple-select-c"
              value={ticker}
              onChange={handleTicker}
            >
              {
                getCoinNamesList(exchange).map((item) => (
                  <MenuItem key={item.ticker} value={item.ticker}>
                    {item.name}
                    {' '}
(
                    {item.ticker}
)
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <br />
          <br />
          <TextField
            fullWidth
            required
            type="number"
            margin="dense"
            variant="filled"
            id="amount"
            value={amount || ''}
            label="Amount"
            error={errorText.amount.length !== 0}
            helperText={errorText.amount.length ? errorText.amount : ''}
            onChange={handleAmount}
            onBlur={(event) => setAmount(Number(event.target.value))}
          />
          <br />
          <br />
          <TextField
            fullWidth
            required
            type="number"
            margin="dense"
            variant="filled"
            id="price"
            value={price || ''}
            label="Price (USD)"
            error={errorText.price.length !== 0}
            helperText={errorText.price.length ? errorText.price : ''}
            onChange={handlePrice}
            onBlur={(event) => setPrice(Number(event.target.value))}
          />
          <br />
          <br />
          <TextField
            fullWidth
            type="number"
            margin="dense"
            variant="filled"
            id="fee"
            value={fee || ''}
            label="Trading Fee"
            error={errorText.fee.length !== 0}
            helperText={errorText.fee.length ? errorText.fee : ''}
            onChange={handleFee}
            onBlur={(event) => setFee(Number(event.target.value))}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableBuyBtn()}
            onClick={handleBuyBtn}
            color="primary"
            autoFocus
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BuyCoins.defaultProps = {
  portfolioCash: 0
};

BuyCoins.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  portfolioID: PropTypes.string.isRequired,
  portfolioCash: PropTypes.number,
  updatePortfolio: PropTypes.func.isRequired
};
