import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  Paper,
  makeStyles
} from '@material-ui/core';
import DepositOrWithdraw from './deposit_withdraw';
import BuyCoins from './buy_coins';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    backgroundColor: '#444568',
    color: theme.palette.text.secondary
  }
}));


function ShowProfit({ portfolio }) {
  return (
    <div>
      <div style={{ color: '#c1c1c1', fontSize: '16px', paddingTop: '46px' }}>
        Profit
      </div>
      <div style={{ color: 'white', fontSize: '35px', paddingTop: '10px' }}>
        {`${portfolio.profit >= 0 ? '$' : '-$'}${Math.abs(portfolio.profit)}`}
        {' '}
        <span style={{ color: portfolio.profit >= 0 ? '#00be9e' : 'red' }}>
          {`(${portfolio.profit_pct}%)`}
        </span>
      </div>
    </div>
  );
}

ShowProfit.propTypes = {
  portfolio: PropTypes.object.isRequired
};


function PortfolioOperations(props) {
  const {handleDepositOrWithdrawBtn, setBuyCoins} = props;

  return (
    <div>
      <Button size="large" style={{color: '#0eb3ff'}}
        onClick={() => handleDepositOrWithdrawBtn('Withdraw')}
      >
      Withdraw
      </Button>
      <Button size="large" style={{color: '#0eb3ff'}}
        onClick={() => handleDepositOrWithdrawBtn('Deposit')}
      >
      Deposit
      </Button>
      <Button size="large" style={{color: '#0eb3ff'}}
        onClick={() => setBuyCoins(true)}
      >
      Buy Coins
      </Button>
    </div>
  );
}

PortfolioOperations.propTypes = {
  handleDepositOrWithdrawBtn: PropTypes.func.isRequired,
  setBuyCoins: PropTypes.func.isRequired
};


function PortfolioOverview(props) {
  const { portfolio, updatePortfolio } = props;
  const classes = useStyles();
  const [depositOrWithdraw, setDepositOrWithdraw] = useState(false);
  const [depositOrWithdrawType, setDepositOrWithdrawType] = useState('');
  const [buyCoins, setBuyCoins] = useState(false);

  const handleDepositOrWithdrawBtn = (type) => {
    setDepositOrWithdrawType(type);
    setDepositOrWithdraw(true);
  };

  return (
    <div>
      {
        buyCoins ? (
          <BuyCoins
            open={buyCoins}
            setOpen={setBuyCoins}
            portfolioID={portfolio._id}
            portfolioCash={portfolio.cash}
            updatePortfolio={updatePortfolio}
          />
        ) : null
      }
      {
        depositOrWithdraw ? (
          <DepositOrWithdraw
            type={depositOrWithdrawType}
            open={depositOrWithdraw}
            setOpen={setDepositOrWithdraw}
            portfolioID={portfolio._id}
            portfolioCash={portfolio.cash}
            updatePortfolio={updatePortfolio}
          />
        ) : null
      }
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper elevation={0} className={classes.paper} >
              <div style={{color: 'white', fontSize: '20px'}}>
                Portfolio Overview
              </div>
              <div style={{ color: '#c1c1c1', fontSize: '16px', paddingTop: '18px' }}>
                Equity
              </div>
              <div style={{ color: 'white', fontSize: '35px', paddingTop: '10px' }}>
                {`$${portfolio.equity_value}`}
              </div>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper elevation={0} className={classes.paper} >
              <ShowProfit portfolio={portfolio} />
            </Paper>
          </Grid>
          {
            (portfolio.type !== 'limit_shared') ? (
              <>
                <div style={{ paddingTop: '22px', paddingBottom: '13px' }}>
                  <Divider orientation="vertical" style={{ backgroundColor: 'white' }}/>
                </div>
                <Grid item xs>
                  <Paper elevation={0} className={classes.paper} >
                    <div style={{ color: '#c1c1c1', fontSize: '16px', paddingTop: '46px' }}>
                    Buying Power
                    </div>
                    <div style={{ color: 'white', fontSize: '35px', paddingTop: '10px' }}>
                      {`$${portfolio.cash}`}
                    </div>
                    <br />
                    {
                      (portfolio.type !== 'full_shared') ? (
                        <PortfolioOperations
                          handleDepositOrWithdrawBtn={handleDepositOrWithdrawBtn}
                          setBuyCoins={setBuyCoins}
                        />
                      ) : null
                    }
                  </Paper>
                </Grid>
              </>) : null
          }
        </Grid>
      </Grid>
    </div>
  );
}

PortfolioOverview.propTypes = {
  portfolio: PropTypes.object.isRequired,
  updatePortfolio: PropTypes.func.isRequired
};


/*
 * This component is the main component to show portfolio's (including shared)
 * overview data (profit, current market value, buying power. Also, widthraw,
 * deposit and buy coins functionalities).
 * Note that if portfolio type is shared then widthraw, deposit and buy coins
 * functionalities are not provided.
 */
export default function PortfolioOverviewComp(props) {
  const { classes, portfolios, selected, updatePortfolio } = props;
  const found = (portfolios.length && selected) ?
    portfolios.filter((e) => e._id === selected._id)[0] : null;

  return (
    <Paper className={classes.paper} style={{backgroundColor: '#444568', paddingTop: '2px'}} >
      {
        found ? (
          <PortfolioOverview
            portfolio={found}
            updatePortfolio={updatePortfolio}
          />
        ) : null
      }
    </Paper>
  );
}

PortfolioOverviewComp.defaultProps = {
  selected: null
};

PortfolioOverviewComp.propTypes = {
  classes: PropTypes.object.isRequired,
  portfolios: PropTypes.array.isRequired,
  selected: PropTypes.object,
  updatePortfolio: PropTypes.func.isRequired
};
