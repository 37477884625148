import React from 'react';


export const UserContext = React.createContext();


export function getUserData() {
  return JSON.parse(localStorage.getItem('user'));
}


export function authHeader(userData) {
  return {headers: {authorization: userData.access_token}};
}


export function getAuthHeader() {
  const userData = getUserData() || {};
  return {headers: {authorization: userData.access_token}};
}


export function authSignOutHeader({...userData}) {
  return {headers: {authorization: JSON.stringify({
    refresh_token: userData.refresh_token,
    access_token: userData.access_token
  })}};
}
