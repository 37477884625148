import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  AppBar,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  TextField,
  makeStyles,
  IconButton,
  Paper,
  Tab,
  Tabs
} from '@material-ui/core';
import ChangePassword from './change_password';
import { listTabText } from '../utils';
import { UserContext } from '../auth';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  profile: {
    paddingLeft: '12px',
    paddingRight: '12px',
    minWidth: '160px'
  }
}));


export default function AppBarComponent(props) {
  const { userData, selectedItem, open, setOpen } = props;
  const { userSignOut } = useContext(UserContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCPDialog, setOpenCPDialod] = useState(false);

  const handleSignOut = () => {
    setAnchorEl(null);
    userSignOut();
  };

  const setChangePasswordDialog = (value) => {
    setOpenCPDialod(value);
    if (value) setAnchorEl(null);
  };

  return (
    <>
      {
        openCPDialog ? (
          <ChangePassword
            open={openCPDialog}
            setOpen={setChangePasswordDialog}
            username={userData ? userData.username : ''}
          />
        ) : null
      }
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            { listTabText(selectedItem) }
          </Typography>
          {
            userData && userData.username ? (
              <div style={{marginLeft: 'auto'}}>
                <Button
                  color="inherit"
                  className={classes.profile}
                  aria-haspopup="true"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <AccountCircle />
                  &nbsp;
                  {userData.username}
                  &nbsp;
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => setChangePasswordDialog(true)}>
                    Change Password
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleSignOut}>
                    Sign Out
                  </MenuItem>
                </Menu>
              </div>
            ) : null
          }
        </Toolbar>
      </AppBar>
    </>
  );
}

AppBarComponent.defaultProps = {
  userData: null
};

AppBarComponent.propTypes = {
  userData: PropTypes.object,
  selectedItem: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};


export function PortfoliosTab(props) {
  const { portfolios, tabValue, selectPortfolio, updatePortfolio, deletePortfolio } = props;

  return (
    <div style={{ flexGrow: 1, marginTop: '-24.6px', height: '71px' }}>
      <Paper square>
        { tabValue >= 0 && portfolios.length ? (
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, idx) => selectPortfolio(idx)}
          >
            {
              portfolios.map((p, i) => <PortfolioTab
                portfolio={p}
                portId={p._id}
                key={p._id}
                idx={i}
                onClick={selectPortfolio}
                updatePortfolio={updatePortfolio}
                deletePortfolio={deletePortfolio}
              />)
            }
          </Tabs>
        ) : null
        }
      </Paper>
    </div>
  );
}

PortfoliosTab.propTypes = {
  portfolios: PropTypes.array.isRequired,
  tabValue: PropTypes.number.isRequired,
  selectPortfolio: PropTypes.func.isRequired,
  updatePortfolio: PropTypes.func.isRequired,
  deletePortfolio: PropTypes.func.isRequired
};


function PortfolioTab(props) {
  const { portfolio, portId, idx, onClick, updatePortfolio, deletePortfolio } = props;
  const [openDelPortDialog, setOpenDelPortDialog] = useState(false);
  const [openSharingDialog, setOpenSharingDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const ITEM_HEIGHT = 48;
  const options = [
    'Add/Edit Sharing',
    'Reset',
    'Delete'
  ];

  const handleOptionClick = (value) => {
    setAnchorEl(null);
    if (value === options[0]) setOpenSharingDialog(true);
    if (value === options[1]) setOpenResetDialog(true);
    if (value === options[2]) setOpenDelPortDialog(true);
  };

  const handleClick = (event) => {
    if (!portfolio.type) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {
        openDelPortDialog ? (
          <ConfirmationDialog
            open={openDelPortDialog}
            setOpen={setOpenDelPortDialog}
            name={portfolio.name}
            portfolioID={portfolio._id}
            submit={deletePortfolio}
            type='delete'
          />
        ) : null
      }
      {
        openResetDialog ? (
          <ConfirmationDialog
            open={openResetDialog}
            setOpen={setOpenResetDialog}
            name={portfolio.name}
            portfolioID={portfolio._id}
            submit={updatePortfolio}
            type='reset'
          />
        ) : null
      }
      {
        openSharingDialog ? (
          <EditSharingDialog
            open={openSharingDialog}
            setOpen={setOpenSharingDialog}
            portfolio={portfolio}
            updatePortfolio={updatePortfolio}
          />
        ) : null
      }
      <div style={{ height: '40px', marginLeft: '-18px', marginRight: '35px' }}>
        <IconButton
          style={{ marginRight: '-15px' }}
          size='medium'
          key={`${portId}IconButton`}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Button
          size='medium'
          key={`${portId}Button`}
          onClick={() => onClick(idx)}
        >
          {portfolio.name}
        </Button>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch'
            }
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              selected={option === 'Pyxis'}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
}

PortfolioTab.propTypes = {
  portfolio: PropTypes.object.isRequired,
  portId: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  updatePortfolio: PropTypes.func.isRequired,
  deletePortfolio: PropTypes.func.isRequired
};


// This component (confirmation dialog) is designed only for portfolio
// deleting and reseting.
function ConfirmationDialog(props) {
  const { open, setOpen, name, portfolioID, submit, type } = props;

  const handleDialogYes = () => {
    if (type === 'delete') {
      submit(portfolioID);
    } else if (type === 'reset') {
      submit({_id: portfolioID}, 'reset');
    }
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {type === 'delete' ? 'Delete' : 'Reset'}
          {' '}
          {name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              type === 'delete' ?
                'Are you sure you want to permanently delete this portfolio?' :
                'Are you sure you want to reset this portfolio?'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={handleDialogYes} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  portfolioID: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};


function EditSharingDialog(props) {
  const { open, setOpen, portfolio, updatePortfolio } = props;
  const [errorText, setErrorText] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [username, setUsername] = useState('');
  const [fullSharedUsers, setFullSharedUsers] = useState(portfolio.full_shared_users);
  const [limitSharedUsers, setLimitSharedUsers] = useState(portfolio.limit_shared_users);

  const handleUsername = (event) => {
    setErrorText('');
    setUsername(event.target.value);
  };

  const handleDelete = (user) => {
    let newlist = tabValue === 0 ? fullSharedUsers : limitSharedUsers;
    newlist = newlist.filter((element) => element !== user);
    if (tabValue === 0) {
      setFullSharedUsers(newlist);
    } else {
      setLimitSharedUsers(newlist);
    }
  };

  const handleAddBtn = () => {
    setErrorText('');
    if (username) {
      const newlist = tabValue === 0 ? fullSharedUsers : limitSharedUsers;
      const found = newlist.find((element) => element === username);
      if (!found) {
        newlist.push(username);
        if (tabValue === 0) {
          setFullSharedUsers(newlist);
        } else {
          setLimitSharedUsers(newlist);
        }
      } else {
        setErrorText(`${username} already added`);
      }
      setUsername('');
    }
  };

  const handleSaveBtn = () => {
    const data = {
      _id: portfolio._id,
      full_shared_users: fullSharedUsers,
      limit_shared_users: limitSharedUsers
    };
    updatePortfolio(data, 'share');
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {`Share Portfolio (${portfolio.name})`}
        </DialogTitle>
        <DialogContent dividers>

          <Paper square>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setTabValue(newValue)}
              aria-label="disabled tabs example"
            >
              <Tab label={`Full Shared Users (${fullSharedUsers.length})`} />
              <Tab label={`Limit Shared Users (${limitSharedUsers.length})`} />
            </Tabs>
          </Paper>
          {
            tabValue === 1 ? (
              <List>
                {limitSharedUsers.map((item) => (
                  <ListItem key={item}>
                    <ListItemText primary={item} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : null
          }
          {
            tabValue === 0 ? (
              <List>
                {fullSharedUsers.map((item) => (
                  <>
                    <ListItem key={item} >
                      <ListItemText primary={item} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            ) : null
          }
          <br />
          <br />
          <TextField
            fullWidth
            required
            type="text"
            margin="dense"
            variant="filled"
            id="username"
            value={username || ''}
            label="Type Username Here to Add"
            error={errorText.length !== 0}
            helperText={errorText.length ? errorText : ''}
            onChange={handleUsername}
            onBlur={(event) => setUsername((event.target.value).trim().toLowerCase())}
          />
          <br />
          <br />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAddBtn}
          >
              Add
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveBtn} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditSharingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  portfolio: PropTypes.object.isRequired,
  updatePortfolio: PropTypes.func.isRequired
};
