import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Grid,
  Button,
  ButtonGroup,
  Paper
} from '@material-ui/core';
import SellCoins from './sell_coins';
import { capitalize } from '../utils';
import { tableIcons } from '../table_icons';


function ShowExchangeTable(props) {
  const { handleActionsClick, exchange, data } = props;

  return (
    <div>
      <br />
      <br />
      <MaterialTable
        style={{ borderRadius: '10px' }}
        icons={tableIcons}
        title={capitalize(exchange)}
        data={data}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          sorting: true,
          pageSize : data.length,
          headerStyle: {
            borderWidth: '1px',
            borderColor: 'black',
            color: '#848484'
          }
        }}
        actions={[
          {
            icon: () => (<MoreVertIcon />),
            tooltip: 'Sell Coins',
            onClick: (event, rowData) => {
              handleActionsClick(exchange, rowData);
            }
          }
        ]}
        columns={[
          {
            title: 'Ticker',
            field: 'ticker',
            render: (row) => (
              <div>
                <img src={row.icon_url} alt="logo" style={{ width: 18, borderRadius: '1%', float: 'left' }}/>
                <div display="inline" style={{ marginLeft: '30px' }}>
                  {`${row.ticker}`}
                </div>
              </div>
            )
          }, {
            title: 'Quantity',
            field: 'amount'
          },  {
            title: 'Avg Entry',
            field: 'avg_cost',
            render: (row) => (
              <div>
                {`$${row.avg_cost}`}
              </div>
            )
          }, {
            title: 'Profit',
            field: 'profit',
            render: (row) => (
              <div>
                {`${row.profit >= 0 ? '$' : '-$'}${Math.abs(row.profit)}`}
                {' '}
                <span style={{ color: row.profit >= 0 ? '#00be9e' : 'red' }}>
                  {`(${row.profit_pct}%)`}
                </span>
              </div>
            )
          }, {
            title: 'Market Value',
            field: 'equity_value',
            render: (row) => (
              <div>
                {`$${row.equity_value}`}
              </div>
            )
          }, {
            title: 'Price/Change (00:00 UTC)',
            field: 'change_utc',
            render: (row) => (
              <div>
                {
                  row.change_utc < 0 ? (
                    <>
                      {`$${row.current_price} / `}
                      <span style={{ color: 'red' }}>
                        {`(${row.change_utc}%)`}
                      </span>
                    </>
                  ) : (
                    <>
                      {`$${row.current_price} / `}
                      <span style={{ color: '#00be9e' }}>
                        {`(${row.change_utc}%)`}
                      </span>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
      />
    </div>
  );
}

ShowExchangeTable.defaultProps = {
  data: []
};

ShowExchangeTable.propTypes = {
  handleActionsClick: PropTypes.func.isRequired,
  exchange: PropTypes.string.isRequired,
  data: PropTypes.array
};


// The main difference between ShowExchangeTable is that this does not
// show/provide an action column
function ShowSharedExchangeTable(props) {
  const { exchange, data } = props;

  return (
    <div>
      <br />
      <br />
      <MaterialTable
        style={{ borderRadius: '10px' }}
        icons={tableIcons}
        title={capitalize(exchange)}
        data={data}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          sorting: true,
          pageSize : data.length,
          headerStyle: {
            borderWidth: '1px',
            borderColor: 'black',
            color: '#848484'
          }
        }}
        columns={[
          {
            title: 'Ticker',
            field: 'ticker',
            render: (row) => (
              <div>
                <img src={row.icon_url} alt="logo" style={{ width: 18, borderRadius: '1%', float: 'left' }}/>
                <div display="inline" style={{ marginLeft: '30px' }}>
                  {`${row.ticker}`}
                </div>
              </div>
            )
          }, {
            title: 'Quantity',
            field: 'amount'
          },  {
            title: 'Avg Entry',
            field: 'avg_cost',
            render: (row) => (
              <div>
                {`$${row.avg_cost}`}
              </div>
            )
          }, {
            title: 'Profit',
            field: 'profit',
            render: (row) => (
              <div>
                {`${row.profit >= 0 ? '$' : '-$'}${Math.abs(row.profit)}`}
                {' '}
                <span style={{ color: row.profit >= 0 ? '#00be9e' : 'red' }}>
                  {`(${row.profit_pct}%)`}
                </span>
              </div>
            )
          }, {
            title: 'Market Value',
            field: 'equity_value',
            render: (row) => (
              <div>
                {`$${row.equity_value}`}
              </div>
            )
          }, {
            title: 'Price/Change (00:00 UTC)',
            field: 'change_utc',
            render: (row) => (
              <div>
                {
                  row.change_utc < 0 ? (
                    <>
                      {`$${row.current_price} / `}
                      <span style={{ color: 'red' }}>
                        {`(${row.change_utc}%)`}
                      </span>
                    </>
                  ) : (
                    <>
                      {`$${row.current_price} / `}
                      <span style={{ color: '#00be9e' }}>
                        {`(${row.change_utc}%)`}
                      </span>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
      />
    </div>
  );
}

ShowSharedExchangeTable.defaultProps = {
  data: []
};

ShowSharedExchangeTable.propTypes = {
  exchange: PropTypes.string.isRequired,
  data: PropTypes.array
};


function ShowByExchanges(props) {
  const { portfolio, handleActionsClick } = props;
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    const result = {};
    Object.keys(portfolio.coins).forEach((exchange) => {
      const data = portfolio.coins[exchange];
      result[exchange] = Object.keys(data).map((ticker) => data[ticker]);
    });
    setTableData(result);
  }, [portfolio]);

  return (
    <div>
      {
        !portfolio.type ? (
          Object.keys(portfolio.coins).map((exchange) => (
            <ShowExchangeTable
              key={exchange}
              handleActionsClick={handleActionsClick}
              exchange={exchange}
              data={tableData[exchange] ? tableData[exchange] : []}
            />
          ))) : (
          Object.keys(portfolio.coins).map((exchange) => (
            <ShowSharedExchangeTable
              key={exchange}
              exchange={exchange}
              data={tableData[exchange] ? tableData[exchange] : []}
            />
          )))
      }
      <br />
      <br />
    </div>
  );
}

ShowByExchanges.propTypes = {
  portfolio: PropTypes.object.isRequired,
  handleActionsClick: PropTypes.func.isRequired
};


function ShowByCoins(props) {
  const { portfolio } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const extra_data = portfolio.coins_extra_data;
    setData(Object.keys(extra_data).map((ticker) => extra_data[ticker]));
  }, [portfolio]);

  return (
    <div>
      <br />
      <br />
      <MaterialTable
        style={{ borderRadius: '10px' }}
        icons={tableIcons}
        title='All Coins'
        data={data}
        options={{
          paging: false,
          search: false,
          sorting: true,
          pageSize : data.length,
          headerStyle: {
            borderWidth: '1px',
            borderColor: 'black',
            color: '#848484'
          }
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            render: (row) => (
              <div>
                {`${row.name}`}
              </div>
            )
          }, {
            title: 'Quantity',
            field: 'total_amount'
          }, {
            title: 'Profit',
            field: 'profit',
            render: (row) => (
              <div>
                {`${row.profit >= 0 ? '$' : '-$'}${Math.abs(row.profit)}`}
                {' '}
                <span style={{ color: row.profit >= 0 ? '#00be9e' : 'red' }}>
                  {`(${row.profit_pct}%)`}
                </span>
              </div>
            )
          }, {
            title: 'Market Value',
            field: 'total_value',
            render: (row) => (
              <div>
                {`$${row.total_value}`}
              </div>
            )
          }, {
            title: 'Diversity',
            field: 'diversity',
            render: (row) => (
              <div>
                {`${row.diversity}%`}
              </div>
            )
          }
        ]}
      />
      <br />
      <br />
    </div>
  );
}

ShowByCoins.propTypes = {
  portfolio: PropTypes.object.isRequired
};


function PortfolioCoinsData(props) {
  const { portfolio, updatePortfolio } = props;
  const [selectedType, setSelectedType] = useState(0);
  const [sellCoins, setSellCoins] = useState(false);
  const [sellCoinData, setSellCoinData] = useState({});

  const handleActionsClick = (exchange, data) => {
    setSellCoins(true);
    setSellCoinData({id: portfolio._id, exchange, coin: data});
  };

  return (
    <div>
      {
        portfolio && Object.keys(portfolio.coins).length ? (
          <div>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              <ButtonGroup size='small' aria-label="small outlined button group">
                {
                  [{idx: 0, name: 'By Exchanges'}, {idx: 1, name: 'By Coins'}].map((btn) => (
                    <Button
                      style={{backgroundColor: btn.idx === selectedType ? 'lightgray' : 'white'}}
                      size='small'
                      key={btn.idx}
                      onClick={() => setSelectedType(btn.idx)}
                    >
                      {btn.name}
                    </Button>
                  ))
                }
              </ButtonGroup>
            </Grid>
            {
              selectedType === 0 ? (
                <ShowByExchanges portfolio={portfolio} handleActionsClick={handleActionsClick} />
              ) : (
                <ShowByCoins portfolio={portfolio} />
              )
            }
            {
              sellCoins ? (
                <SellCoins
                  open={sellCoins}
                  setOpen={setSellCoins}
                  data={sellCoinData}
                  updatePortfolio={updatePortfolio}
                />
              ) : null
            }
          </div>
        ) : (
          <div>
            Buy Coins to See Coins' Table Here
          </div>
        )
      }
    </div>
  );
}

PortfolioCoinsData.defaultProps = {
  portfolio: undefined
};

PortfolioCoinsData.propTypes = {
  portfolio: PropTypes.object,
  updatePortfolio: PropTypes.func.isRequired
};


/*
 * This component is the main component to show portfolio's (including shared)
 * coins data (amount, average price per coin, profit, current market value and
 * current price) in a table format.
 * Note that if portfolio type is 'limit shared' then do not show this table.
 * Also, portfolio coins' table has a 'action' column (to sell coins) whish would
 * be available only for portfolio owners. If it is shared it won't be available.
 */
export default function PortfolioCoinsComp(props) {
  const { classes, portfolios, selected, updatePortfolio } = props;

  return (
    <Paper className={classes.paper} >
      {
        (portfolios.length && selected && !selected.type) ||
        (portfolios.length && selected && selected.type && selected.type === 'full_shared') ?
          (
            <PortfolioCoinsData
              portfolio={portfolios.filter((e) => e._id === props.selected._id)[0]}
              updatePortfolio={updatePortfolio}
            />
          ) : null
      }
    </Paper>
  );
}

PortfolioCoinsComp.defaultProps = {
  selected: null
};

PortfolioCoinsComp.propTypes = {
  classes: PropTypes.object.isRequired,
  portfolios: PropTypes.array.isRequired,
  selected: PropTypes.object,
  updatePortfolio: PropTypes.func.isRequired
};
