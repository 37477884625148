import React, { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Copyright from './components/copyright';
import { UserContext } from './auth';
import { api } from './api';
import { verifyPlainPassword, createAlert } from './utils';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  container: {
    minHeight: '100vh'
  }
}));


export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const { userSignedIn, userSignIn } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [usernameErrorText, setUsernameErrorText] = useState('');
  const [signInError, setSignInError] = useState('');
  const alert = createAlert(useSnackbar().enqueueSnackbar);

  if (userSignedIn) history.push('/coins');

  async function SignInUser(request) {
    try {
      const rv = await api.post(`/user/${username}/sign-in`, request);
      if (rv.data.error) {
        setSignInError(rv.data.error);
        return null;
      }
      return rv.data;
    } catch (error) {
      alert('Error', error);
    }

    return null;
  }

  const handleSignIn = async () => {
    if (!username && !password) {
      setSignInError('Please provide username and password');
      return;
    }

    setSignInError('');
    setPasswordErrorText('');
    setUsernameErrorText('');
    if (username.length < 4) {
      setUsernameErrorText('Username length must be at least 4 characters');
      return;
    }
    if (!verifyPlainPassword(password)) {
      setPasswordErrorText(`
        Password must be:
        at least 6 characters, include a digit number,
        an upper case and a lower case letters, and a special character.
      `);
      return;
    }

    const rv = await SignInUser({username, password});
    if (rv) {
      userSignIn(rv);
      history.push('/coins');
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {
            signInError ? (
              <Alert severity="error">
                {signInError}
              </Alert>
            ) : null
          }
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              error={usernameErrorText.length !== 0}
              helperText={usernameErrorText.length ? usernameErrorText : ''}
              onChange={(event) => setUsername(event.target.value)}
              onBlur={(event) => setUsername((event.target.value).trim())}
            />
            <br />
            <br />
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error={passwordErrorText.length !== 0}
              helperText={passwordErrorText.length ? passwordErrorText : ''}
              onChange={(event) => setPassword(event.target.value)}
              onBlur={(event) => setPassword((event.target.value).trim())}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSignIn}
            >
              Sign In
            </Button>
            <Grid item>
              <Link href="sign-up" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

