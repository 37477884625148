import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';


export default function DepositOrWithdraw(props) {
  const {
    type, open, setOpen, portfolioID, portfolioCash, updatePortfolio
  } = props;
  const [amount, setAmount] = useState(0);
  const [errorText, setErrorText] = useState('');
  const amountRef = useRef();

  const handleOnChange = (event) => {
    setErrorText('');
    setAmount(event.target.value);
    const value = Number(event.target.value);
    if (value <= 0) {
      setErrorText('Provided amount must be greater than zero');
    }
    if (type === 'Withdraw' && value > portfolioCash) {
      setErrorText('You cannot withdraw more than your cash balance');
    }
  };

  const handleSubmitBtn = async () => {
    const updateType = type === 'Deposit' ? 'deposit' : 'withdraw';
    updatePortfolio({_id: portfolioID, amount}, updateType);
    setOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!amount) amountRef.current.focus();
    }, 200);

    return () => { clearTimeout(timeout); };
  }, [amount]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="deposit-withdraw-portfolio-dialog"
        fullWidth
      >
        <DialogTitle id="add-subjects-dialog-title" onClose={() => setOpen(false)}>
          {type}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            inputRef={amountRef}
            fullWidth
            required
            type="number"
            margin="dense"
            variant="filled"
            id="amount"
            value={amount || ''}
            label="Amount"
            error={errorText.length !== 0}
            helperText={errorText.length ? errorText : ''}
            onChange={handleOnChange}
            onBlur={(event) => setAmount(Number(event.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={errorText.length !== 0 || amount <= 0}
            onClick={handleSubmitBtn}
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DepositOrWithdraw.defaultProps = {
  portfolioCash: 0
};

DepositOrWithdraw.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  portfolioID: PropTypes.string.isRequired,
  portfolioCash: PropTypes.number,
  updatePortfolio: PropTypes.func.isRequired
};
