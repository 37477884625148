/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid, makeStyles } from '@material-ui/core';
import SpinnerPage from './components/spinner_page';
import { getAuthHeader } from './auth';
import { api } from './api';
import { createAlert, urlParamChanged } from './utils';
import PortfolioCoinsComp from './components/port_coins';
import PortfolioChartComp from './components/chart';
import { PortfoliosTab } from './components/app_bar';
import PortfolioOverviewComp from './components/port_overview';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '10px',
    color: theme.palette.text.secondary
  }
}));


export default function SharedPortfoliosView({ socket }) {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [portfolios, setPortfolios] = useState([]);
  const [selected, _setSelected] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [wsChartData, setWsChartData] = useState([]);
  const [runSpinningPage, setRunSpinningPage] = useState(false);
  const alert = createAlert(useSnackbar().enqueueSnackbar);

  const setSelected = (data, wsFeed = false) => {
    if (!wsFeed) fetchSharedChartData(data._id);
    _setSelected(data);
    history.push(`/shared_portfolios/${data._id}`);
  };

  const selectPortfolio = (idx) => {
    setTabValue(idx);
    setSelected(portfolios[idx]);
  };

  async function fetchSharedPortfolios() {
    try {
      const rv = await api.get('/shared_data/portfolios', getAuthHeader());
      setPortfolios(rv.data);

      // If there is NO any ID in the URL, then redirect the user to the ID of
      // the first consent form if there is one.
      if (!params.id && rv.data.length) setSelected(rv.data[0]);
    } catch (error) {
      alert('Error while fetching sharing portfolios', error);
    }
  }

  async function fetchSharedChartData(id) {
    try {
      const rv = await api.get(`/shared_data/${id}/charts`, getAuthHeader());
      setChartData(rv.data);
    } catch (error) {
      alert('Error while fetching shared chart data', error);
    }
  }

  useEffect(() => {
    (async () => {
      setRunSpinningPage(true);
      await fetchSharedPortfolios();
      setRunSpinningPage(false);
    })();
  }, []);


  useEffect(() => {
    const idx = urlParamChanged(params, portfolios, selected);
    if (idx >= 0) selectPortfolio(idx);
  }, [params, selected, portfolios]);


  useEffect(() => {
    if (socket) {
      socket.on('ws_shared_portfolios', (data) => {
        if (data) setPortfolios(data);
      });
      socket.on('ws_shared_charts', (data) => {
        if (data) setWsChartData(data);
      });
    }

    // Cleanup.
    return () => {
      if (socket) {
        socket.off('ws_shared_portfolios');
        socket.off('ws_shared_charts');
      }
    };
  }, [socket]);


  return (
    <div className={classes.root} style={{ width: '100px' }}>
      {
        tabValue >= 0 && portfolios.length ? (
          <PortfoliosTab
            portfolios={portfolios}
            tabValue={tabValue}
            selectPortfolio={selectPortfolio}
            updatePortfolio={() => {}}
            deletePortfolio={() => {}}
          />
        ) : null
      }
      { runSpinningPage ? (
        <SpinnerPage />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PortfolioOverviewComp
              classes={classes}
              portfolios={portfolios}
              selected={selected}
              updatePortfolio={() => {}}
            />
          </Grid>
          <Grid item xs={12}>
            <PortfolioChartComp
              classes={classes}
              portfolios={portfolios}
              selected={selected}
              chartData={chartData}
              wsChartData={wsChartData}
              portType='shared'
            />
          </Grid>
          <Grid item xs={12}>
            <PortfolioCoinsComp
              classes={classes}
              portfolios={portfolios}
              selected={selected}
              updatePortfolio={() => {}}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

SharedPortfoliosView.defaultProps = {
  socket: null
};

SharedPortfoliosView.propTypes = {
  socket: PropTypes.object
};
