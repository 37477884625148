import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { capitalize } from '../utils';


export default function SellCoins(props) {
  const {open, setOpen, data, updatePortfolio } = props;
  const [amount, setAmount] = useState(data.coin.amount);
  const [price, setPrice] = useState(data.coin.current_price);
  const [fee, setFee] = useState(0);
  const [errorText, setErrorText] = useState('');

  const handleAmount = (event) => {
    setErrorText('');
    const value = event.target.value;
    setAmount(value);
    if (value < 0) {
      setErrorText('Provided amount must be greater than zero');
    }
  };

  const handlePrice = (event) => {
    setPrice(event.target.value);
  };

  const disableSellBtn = () => {
    if (!data.exchange || !data.coin.ticker || amount <= 0 || price < 0 || errorText) return true;
    if (amount > data.coin.amount) {
      setErrorText(`You have only ${data.coin.amount} coin(s) to sell`);
      return true;
    }
    return false;
  };

  const handleSellBtn = () => {
    const request = {
      _id: data.id,
      ticker: data.coin.ticker,
      amount,
      price,
      exchange: data.exchange,
      fee
    };
    updatePortfolio(request, 'sell_coin');
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="deposit-withdraw-portfolio-dialog"
        fullWidth
      >
        <DialogTitle id="add-subjects-dialog-title" onClose={() => setOpen(false)}>
          Sell Coins
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            margin="dense"
            id="exchange"
            label="Exchange"
            defaultValue={capitalize(data.exchange)}
            InputProps={{ readOnly: true }}
          />
          <br />
          <br />
          <TextField
            fullWidth
            id="coin_name"
            label="Coin Name"
            defaultValue={data.coin.name}
            InputProps={{ readOnly: true }}
          />
          <br />
          <br />
          <TextField
            fullWidth
            required
            type="number"
            margin="dense"
            variant="filled"
            id="amount"
            value={amount || ''}
            label="Amount"
            error={errorText.length !== 0}
            helperText={errorText.length ? errorText : ''}
            onChange={handleAmount}
          />
          <br />
          <br />
          <TextField
            fullWidth
            required
            type="number"
            margin="dense"
            variant="filled"
            id="price"
            value={price || ''}
            label="Price (USD)"
            onChange={handlePrice}
            onBlur={(event) => setPrice(Number(event.target.value))}
          />
          <br />
          <br />
          <TextField
            fullWidth
            type="number"
            margin="dense"
            variant="filled"
            id="fee"
            value={fee || ''}
            label="Trading Fee"
            onChange={(event) => setFee(Number(event.target.value))}
            onBlur={(event) => setFee(Number(event.target.value))}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableSellBtn()}
            onClick={handleSellBtn}
            color="primary"
            autoFocus
          >
            Sell
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SellCoins.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updatePortfolio: PropTypes.func.isRequired
};
