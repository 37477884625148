import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getAuthHeader } from './auth';
import { api } from './api';
import { createAlert, urlParamChanged } from './utils';
import PortfolioCoinsComp from './components/port_coins';
import SpinnerPage from './components/spinner_page';
import NewPortfolio from './components/new_portfolio';
import PortfolioChartComp from './components/chart';
import { PortfoliosTab } from './components/app_bar';
import PortfolioOverviewComp from './components/port_overview';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '10px',
    color: theme.palette.text.secondary
  }
}));


export default function PortfoliosView({ socket }) {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const [allowWsUpdate, setAllowWSUpdate] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [portfolios, setPortfolios] = useState([]);
  const [selected, _setSelected] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [wsChartData, setWsChartData] = useState([]);
  const [runSpinningPage, setRunSpinningPage] = useState(false);
  const alert = createAlert(useSnackbar().enqueueSnackbar);

  async function fetchChartData(id) {
    try {
      const rv = await api.get(`/chart/${id}/charts`, getAuthHeader());
      setChartData(rv.data);
    } catch (error) {
      alert('Error while fetching chart data', error);
    }
  }

  const resetData = () => {
    setAllowWSUpdate(true);
    _setSelected(null);
    setPortfolios([]);
    setChartData([]);
    setWsChartData([]);
  };

  const setSelected = (data, wsFeed = false) => {
    if (!wsFeed) fetchChartData(data._id);
    history.push(`/portfolios/${data._id}`);
    _setSelected(data);
  };

  const selectPortfolio = (idx) => {
    setTabValue(idx);
    setSelected(portfolios[idx]);
  };

  async function fetchPortfolios() {
    try {
      const rv = await api.get('/portfolio', getAuthHeader());
      setPortfolios(rv.data);

      // If there is NO any ID in the URL, then redirect the user to the ID of
      // the first consent form if there is one.
      if (!params.id && rv.data.length) setSelected(rv.data[0]);
    } catch (error) {
      alert('Error while fetching portfolios', error);
    }
  }

  async function updatePortfolio(data, type) {
    try {
      setAllowWSUpdate(false);
      const rv = await api.post(`/portfolio/${data._id}/${type}`, data, getAuthHeader());
      setPortfolios(portfolios.map((d) => (d._id === rv.data._id ? rv.data : d)));
      const idx = portfolios.findIndex((element) => element._id === rv.data._id);
      selectPortfolio(idx);
    } catch (error) {
      alert('Error while fetching updating portfolios', error);
    } finally {
      setAllowWSUpdate(true);
    }
  }

  async function createPortfolio(portfolio_name) {
    try {
      const rv = await api.post('/portfolio', {name: portfolio_name}, getAuthHeader());
      portfolios.push(rv.data);
      setPortfolios(portfolios);
      setSelected(portfolios[portfolios.length - 1]);
      setTabValue(portfolios.length - 1);
      fetchChartData(portfolios[portfolios.length - 1]._id);
    } catch (error) {
      alert('Error while creating a portfolio', error);
    }
  }

  async function deletePortfolio(id) {
    try {
      setAllowWSUpdate(false);
      await api.delete(`/portfolio/${id}`, getAuthHeader());
      const new_list = portfolios.filter((item) => item._id !== id);
      if (new_list.length) {
        setTabValue(0);
        setSelected(new_list[0]);
        setPortfolios(new_list);
      } else {
        resetData();
        history.replace('/portfolios');
      }
    } catch (error) {
      alert('Error while deleting portfolio', error);
    } finally {
      setAllowWSUpdate(true);
    }
  }


  useEffect(() => {
    (async () => {
      setRunSpinningPage(true);
      await fetchPortfolios();
      setRunSpinningPage(false);
    })();
  }, []);


  useEffect(() => {
    const idx = urlParamChanged(params, portfolios, selected);
    if (idx >= 0) selectPortfolio(idx);
  }, [params, selected, portfolios]);


  useEffect(() => {
    if (socket) {
      socket.on('ws_portfolios', (data) => {
        if (data && allowWsUpdate) setPortfolios(data);
      });
      socket.on('ws_charts', (data) => {
        if (data && allowWsUpdate) setWsChartData(data);
      });
    }

    // Cleanup.
    return () => {
      if (socket) {
        socket.off('ws_portfolios');
        socket.off('ws_charts');
      }
    };
  }, [socket, allowWsUpdate]);

  return (
    <div className={classes.root} style={{ width: '100px' }}>
      {
        tabValue >= 0 && portfolios.length ? (
          <PortfoliosTab
            portfolios={portfolios}
            tabValue={tabValue}
            selectPortfolio={selectPortfolio}
            updatePortfolio={updatePortfolio}
            deletePortfolio={deletePortfolio}
          />
        ) : null
      }
      { runSpinningPage ? (
        <SpinnerPage />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PortfolioOverviewComp
              classes={classes}
              portfolios={portfolios}
              selected={selected}
              updatePortfolio={updatePortfolio}
            />
          </Grid>
          <Grid item xs={12}>
            <PortfolioChartComp
              classes={classes}
              portfolios={portfolios}
              selected={selected}
              chartData={chartData}
              wsChartData={wsChartData}
              portType='owner'
            />
          </Grid>
          <Grid item xs={12}>
            <PortfolioCoinsComp
              classes={classes}
              portfolios={portfolios}
              selected={selected}
              updatePortfolio={updatePortfolio}
            />
          </Grid>
        </Grid>
      )
      }
      <NewPortfolio
        portfolios={portfolios.length ?
          portfolios.reduce((map, obj) => {
            map[obj.name] = 1;
            return map;
          }, {}) : {}
        }
        submit={createPortfolio}
      />
    </div>
  );
}

PortfoliosView.defaultProps = {
  socket: null
};

PortfoliosView.propTypes = {
  socket: PropTypes.object
};
