/*eslint-disable*/
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import socketIOClient from 'socket.io-client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import './index.css';
import App from './app';
import { api, apiURL } from './api';
import { authSignOutHeader, getUserData, UserContext } from './auth';
import * as serviceWorker from './serviceWorker';


/*
 * This component is the main entry point to the entire React application. We
 * perform initialization here and setup various middlewares and contexts that
 * need to be available across the entire application.
 */
function Main() {
  const [userSignedIn, setUserSignedIn] = useState(() => {
    const data = getUserData();
    if (data && data.username) return data.username;
    return null;
  });
  const [socket, setSocket] = useState(() => {
    const data = getUserData();
    if (data && data.username && data.access_token && data.refresh_token) {
      return socketIOClient(apiURL, {query: data, forceNew: true});
    }
    return null;
  });

  const userSignIn = (data) => {
    localStorage.setItem('user', JSON.stringify(data));
    setUserSignedIn(data.username);
    if (data) setSocket(socketIOClient(apiURL, {query: data, forceNew: true}));
  };

  const userSignOut = () => {
    const data = getUserData();
    api.get(`/user/${data.username}/sign-out`, authSignOutHeader(data));
    if (socket) socket.close();
    setUserSignedIn(null);
    localStorage.setItem('user', null);
  };

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <SnackbarProvider maxSnack={5}>
          <UserContext.Provider value={{userSignedIn, userSignIn, userSignOut, socket}}>
            <App />
          </UserContext.Provider>
        </SnackbarProvider>
      </BrowserRouter>
    </>
  );
}

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
